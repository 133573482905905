import { createAction, handleActions } from "redux-actions";
import { produce } from "immer";
import { firestore } from "../../shared/firebase";

const { host, protocol } = window.location;
const lessonURL = `${protocol}//${host}/lesson/`;

// actions
const GET_TUTOR = "GET_TUTOR";
const ADD_TUTOR = "ADD_TUTOR";
const GET_MEMBER = "GET_MEMBER";
const ADD_MEMBER = "ADD_MEMBER";
const UPDATE_LESSON = "UPDATE_LESSON";
const GET_ACCESS = "GET_ACCESS";

// action creators
const getTutor = createAction(GET_TUTOR, (tutor, rq_type) => ({
  tutor,
  rq_type,
}));
const addTutor = createAction(ADD_TUTOR, (tutor, rq_type) => ({
  tutor,
  rq_type,
}));
const getMember = createAction(GET_MEMBER, (member, doc_id) => ({
  member,
  doc_id,
}));
const addMember = createAction(ADD_MEMBER, (member, rq_type) => ({
  member,
  rq_type,
}));
const updateLesson = createAction(UPDATE_LESSON, (lesson, rq_type) => ({
  lesson,
  rq_type,
}));
const getAccess = createAction(GET_ACCESS, (access, rq_type) => ({
  access,
  rq_type,
}));

// initialState
// error, if inbox: "" (string)
const initialState = {
  inbox: [],
  inbox_member: [],
  member_doc_id: "",
  lesson_info: {
    orch: "",
    ins1: "",
    ins1_last: "",
    ins2: "",
    ins2_last: "",
    ins3: "",
    ins3_last: "",
    numberOfInst_init: 0,
    numberOfInst: 0,
  },
  linkAdd: "",
  loading: false,
  access: {
    tgram: "",
    kakao: "",
    temp_access: "",
  },
};

// const initialTutor = {
//   Sq: 1,
//   id: 1001,
//   tCode: 1,
//   tName: "Cd 지휘자",
//   url: "https://youtu.be/k3uVafQegQ0",
//   제목: "01 01 Jack",
//   주차: 1,
//   학기: 1,
//   확인: 101,
// };

const gen_access = (info, proc_router) => {
  const code = info;

  return function (dispatch) {
    switch (proc_router) {
      case "kakao_reg":
        console.log(proc_router);
        const memberDB = firestore
          .collection(docNamesFB.member)
          .doc(info.member_doc_id);

        memberDB
          .set(
            {
              k_ID: info.access.kakao.id,
              k_email: info.access.kakao.kakao_account.email,
              k_register: true,
            },
            { merge: true }
          )
          .then(() => {
            console.log("Document successfully written!");
            window.location.href = lessonURL + info.access.tgram;
            // history.push("/lesson/" + info.access.tgram);
            // window.location.reload();
            // reRender();
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });

        break;
      default:
        dispatch(getAccess(code, proc_router));
    }
  };
};

const docNamesFB = {
  // FB_ DB 이름 지정 상수
  tutor: "test",
  member: "test_member",
};

const getLessonFromID = (lesson_id, rqType = 0) => {
  switch (rqType) {
    case 0:
      const tutor = Math.floor(lesson_id / 1000);
      const sq = lesson_id - 1000 * Math.floor(lesson_id / 1000);
      const sm = Math.ceil(sq / 32);
      const wk = sq - 32 * (sm - 1);
      return {
        tutor: tutor,
        semester: sm,
        week: wk,
        sequence: sq,
      };
    case 1:
      break;
    default:
  }
};

const updateLessonFB = (
  props,
  doc_id,
  lesson_id = 0,
  rqType = 0,
  mbr_code = ""
) => {
  const { history } = props;

  const lesson_details = getLessonFromID(lesson_id);

  const memberDB = firestore.collection(docNamesFB.member).doc(doc_id);

  const reRender = () => {
    history.replace("/lesson");
    history.replace(`/lesson/${mbr_code}`);
  };

  console.log(lesson_details);

  switch (rqType) {
    case 0:
      memberDB
        .set(
          {
            oProg: lesson_id,
            i0c_tutor: lesson_details.tutor,
            i0d_semester: lesson_details.semester,
            i0e_week: lesson_details.week,
            i0u_updated: true,
          },
          { merge: true }
        )
        .then(() => {
          console.log("Document successfully written!");
          window.location.reload();
          // reRender();
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      break;
    case 1:
      memberDB
        .set(
          {
            i1a_lesson: lesson_id,
            i1d_semester: lesson_details.semester,
            i1e_week: lesson_details.week,
            i1u_updated: true,
          },
          { merge: true }
        )
        .then(() => {
          console.log("Document successfully written!");
          window.location.reload();
          // reRender();
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      break;
    case 2:
      memberDB
        .set(
          {
            i2a_lesson: lesson_id,
            i2d_semester: lesson_details.semester,
            i2e_week: lesson_details.week,
            i2u_updated: true,
          },
          { merge: true }
        )
        .then(() => {
          console.log("Document successfully written!");
          window.location.reload();
          // reRender();
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      break;
    default:
  }
};

const addTutorFB = (rdx_data, rq_type) => {
  switch (rq_type) {
    case "newAdd":
      const tutorDB = firestore.collection(docNamesFB.tutor);
      // const tutorDB = firestore.collection("test111");

      tutorDB
        .add({
          id: rdx_data.id,
          tCode: rdx_data.id_fx.tutor,
          notes: rdx_data.notes,
          url: rdx_data.youtube,
          vimeo: rdx_data.vimeo,
        })
        .then(() => {
          window.alert("Document successfully written!");
          // reRender();
        })
        .catch((error) => {
          window.alert("Error writing document: ", error);
        });
      break;
    case "LATER":
      break;
    default:
      console.log("DEFAULT");
  }

  return function (dispatch, getState, { history }) {
    console.log("HELLo");
    // const tutorDB = firestore.collection("mh_lesson");
    // dispatch(addTutor(tutor));
    // const _user = getState().user.user;
    // const user_info = {
    //   user_name: _user.user_name,
    //   user_id: _user.uid,
    //   user_profile: _user.user_profile,
    // };
    // const _tutor = {
    //   ...initialTutor,
    //   tutor: tutor,
    // };
    // console.log({ ...user_info, ..._tutor });
    // return;
    // tutorDB
    //   .add({ ...user_info, ..._tutor })
    //   .then((doc) => {
    //     let tutor = { user_info, ..._tutor, id: doc.id };
    //     dispatch(addtutor(tutor));
    //     history.replace("/");
    //   })
    //   .catch((err) => {
    //     console.log("tutor 작성 실패", err);
    //   });
  };
};

const getTutorFB = (lesson_id) => {
  return function (dispatch, getState, { history }) {
    console.log("작업시작", lesson_id, typeof lesson_id);
    const tutorDB = firestore.collection(docNamesFB.tutor);
    // .where("id", "==", lesson_id)

    let result = "데이타가 없습니다";

    tutorDB
      .where("id", "==", lesson_id)
      .get()
      .then((docs) => {
        const size = docs.size;
        if (size === 1) {
          docs.forEach((doc) => {
            console.log(doc);
            console.log(doc.exists);
            console.log(doc.id);
            console.log(doc.data());
            result = doc.data();
          });
        } else if (size > 1) {
          result = "경고! 중복 데이타 있음";
        }
        dispatch(getTutor(result));
      });

    return result;
  };
};

const getMemberFB = (member_id, qType = 0) => {
  return function (dispatch, getState, { history }) {
    console.log("Member", member_id, typeof member_id, docNamesFB.member);
    const tutorDB = firestore.collection(docNamesFB.tutor);
    const memberDB = firestore.collection(docNamesFB.member);
    let result = "데이타가 없습니다";
    let doc_id = "";

    switch (qType) {
      case 1:
        const cond1 = member_id.name !== undefined && member_id.name !== "";
        const cond2 = member_id.mobile !== undefined && member_id.mobile !== "";
        const cond3 = member_id.ilink !== undefined && member_id.ilink !== "";
        // console.log(cond1, cond2, cond3);

        const processBySize = (docs) => {
          const size = docs.size;
          if (size === 1) {
            docs.forEach((doc) => {
              console.log("------★---------", doc.data());
              dispatch(getMember(doc.data()));
            });
          } else if (size > 1) {
            const rcvd_data = [];
            docs.forEach((doc) => {
              rcvd_data.push(doc.data());
            });
            dispatch(getMember(rcvd_data));
          } else {
            //데이타 없음
          }
        };

        if (cond1 && !cond2 && !cond3) {
          memberDB
            .where("name", "==", member_id.name)
            .get()
            .then((docs) => {
              processBySize(docs);
            });
        } else if (cond1 && cond2 && !cond3) {
          memberDB
            .where("name", "==", member_id.name)
            .where("mobile", "==", member_id.mobile)
            .get()
            .then((docs) => {
              processBySize(docs);
            });
        } else if (!cond1 && cond2 && !cond3) {
          memberDB
            .where("mobile", "==", member_id.mobile)
            .get()
            .then((docs) => {
              processBySize(docs);
            });
        } else if (!cond1 && !cond2 && cond3) {
          memberDB
            .where("ilink", "==", member_id.ilink)
            .get()
            .then((docs) => {
              processBySize(docs);
            });
        } else {
          window.alert(
            "이 조합으로 검색이 가능하지 않습니다.\n다른 검색 조건을 시도해보세요."
          );
        }
        break;

      case 2: // Kakao Login
        memberDB
          .where("k_ID", "==", member_id)
          .get()
          .then((docs) => {
            const size = docs.size;
            if (size === 1) {
              docs.forEach((doc) => {
                console.log(doc);
                console.log(doc.exists);
                console.log(doc.id);
                console.log(doc.data());
                doc_id = doc.id;
                result = doc.data();
                if (doc.exists) {
                  window.location.href = lessonURL + result.tID;
                }
              });
            } else if (size > 1) {
              result = "경고! 중복 데이타 있음";
            }
            window.alert("카카오 등록 데이타 없음");
            console.log("데이타 없는 경우 안내");
            // dispatch(getMember(result, doc_id));
          });
        break;
      default:
        console.log(">>>>> default 진입");
        // let result = "데이타가 없습니다";
        // let doc_id = "";

        const dataFeeder = (docs, rqType) => {
          const size = docs.size;
          switch (size) {
            case 0:
              break;
            case 1:
              docs.forEach((doc) => {
                result = doc.data();
              });
              dispatch(getTutor(result, rqType));
              break;
            default:
              result = "경고! 중복 데이타 있음_orch";
          }
        };

        memberDB
          .where("tID", "==", member_id)
          .get()
          .then((docs) => {
            const size = docs.size;
            if (size === 1) {
              docs.forEach((doc) => {
                console.log(doc);
                console.log(doc.exists);
                console.log(doc.id);
                console.log(doc.data());
                doc_id = doc.id;
                result = doc.data();
                if (doc.exists) {
                  if (
                    doc.data().oProg &&
                    doc.data().i1a_lesson &&
                    doc.data().i2a_lesson
                  ) {
                    dispatch(getTutor(2, 99));
                  } else if (doc.data().oProg && doc.data().i1a_lesson) {
                    dispatch(getTutor(1, 99));
                  } else if (doc.data().oProg) {
                    dispatch(getTutor(0, 99));
                  } else if (doc.data().i1a_lesson) {
                    dispatch(getTutor(1, 99));
                  }

                  if (doc.data().oProg) {
                    tutorDB
                      .where("id", "==", doc.data().oProg)
                      .get()
                      .then((docs) => {
                        dataFeeder(docs, 0);
                      });
                    console.log(">>> orch", result);
                  }
                  if (doc.data().i1a_lesson) {
                    tutorDB
                      .where("id", "==", doc.data().i1a_lesson)
                      .get()
                      .then((docs) => {
                        dataFeeder(docs, 1);
                      });
                    tutorDB
                      .where("id", "==", doc.data().i1a_lesson - 1)
                      .get()
                      .then((docs) => {
                        dataFeeder(docs, 10);
                      });
                  }
                  if (doc.data().i2a_lesson) {
                    tutorDB
                      .where("id", "==", doc.data().i2a_lesson)
                      .get()
                      .then((docs) => {
                        dataFeeder(docs, 2);
                      });
                    tutorDB
                      .where("id", "==", doc.data().i2a_lesson - 1)
                      .get()
                      .then((docs) => {
                        dataFeeder(docs, 20);
                      });
                  }
                }
              });
            } else if (size > 1) {
              result = "경고! 중복 데이타 있음";
            }
            dispatch(getMember(result, doc_id));
          });
    }

    // console.log("진입합니다", doc.data().i1_lesson);
    // getTutorFB(doc.data().i1_lesson);
  };
};

const addMemberFB = (inbox_member) => {
  console.log("접근", inbox_member);
  const MemberDB = firestore.collection(docNamesFB.member);
  const lsn1 = getLessonFromID(inbox_member.ins1.id);
  const lsn2 = getLessonFromID(inbox_member.ins2.id);
  const lsn3 = getLessonFromID(inbox_member.ins3.id);
  const lsn4 = getLessonFromID(inbox_member.ins4.id);

  const lsnInfo = {
    1: {
      id: inbox_member.ins1.id,
      tutor: lsn1.tutor,
      sm: lsn1.semester,
      wk: lsn1.week,
    },
    2: {
      id: inbox_member.ins2.id,
      tutor: lsn2.tutor,
      sm: lsn2.semester,
      wk: lsn2.week,
    },
    3: {
      id: inbox_member.ins3.id,
      tutor: lsn3.tutor,
      sm: lsn3.semester,
      wk: lsn3.week,
    },
    4: {
      id: inbox_member.ins4.id,
      tutor: lsn4.tutor,
      sm: lsn4.semester,
      wk: lsn4.week,
    },
  };

  MemberDB.add({
    name: inbox_member.name,
    dob: inbox_member.dob,
    mobile: inbox_member.mobile,
    orch_name: inbox_member.orch_name,
    tID: inbox_member.tID,
    oProg: inbox_member.ins0.id,
    i1a_lesson: lsnInfo[1].id,
    i1c_tutor: lsnInfo[1].tutor,
    i2a_lesson: lsnInfo[2].id,
    i2c_tutor: lsnInfo[2].tutor,
    i3a_lesson: lsnInfo[3].id,
    i3c_tutor: lsnInfo[3].tutor,
    i4a_lesson: lsnInfo[4].id,
    i4c_tutor: lsnInfo[4].tutor,
  })
    .then(() => {
      window.alert("등록에 성공하였습니다");
      window.location.reload();
    })
    .catch((error) => {
      window.alert("Error writing document: ", error);
    });
};

export default handleActions(
  {
    [GET_TUTOR]: (state, action) =>
      produce(state, (draft) => {
        switch (action.payload.rq_type) {
          case 0:
            draft.lesson_info.orch = action.payload.tutor;
            draft.lesson_info.numberOfInst = action.payload.rq_type;
            // draft.inbox = action.payload.tutor;
            break;
          case 1:
            draft.lesson_info.ins1 = action.payload.tutor;
            draft.lesson_info.numberOfInst = action.payload.rq_type;
            // draft.inbox = action.payload.tutor;
            break;
          case 2:
            draft.lesson_info.ins2 = action.payload.tutor;
            draft.lesson_info.numberOfInst = action.payload.rq_type;
            // draft.inbox = action.payload.tutor;
            break;
          case 10:
            draft.lesson_info.ins1_last = action.payload.tutor;
            draft.lesson_info.numberOfInst = action.payload.rq_type;
            break;
          case 20:
            draft.lesson_info.ins2_last = action.payload.tutor;
            draft.lesson_info.numberOfInst = action.payload.rq_type;
            break;
          case 99:
            draft.lesson_info.numberOfInst_init = action.payload.tutor;
            break;
          default:
            draft.inbox = action.payload.tutor;
        }
        if (
          draft.lesson_info.numberOfInst_init * 10 ===
          draft.lesson_info.numberOfInst
        ) {
          draft.loading = true;
        }
      }),
    [ADD_TUTOR]: (state, action) =>
      produce(state, (draft) => {
        console.log(action.payload);
        switch (action.payload.rq_type) {
          case "newTemp":
            draft.linkAdd = action.payload.tutor;
            break;
          case "deleteTemp":
            draft.linkAdd = "";
            break;
          default:
        }
      }),
    [GET_MEMBER]: (state, action) =>
      produce(state, (draft) => {
        draft.inbox_member = action.payload.member;
        draft.member_doc_id = action.payload.doc_id;
      }),
    [ADD_MEMBER]: (state, action) =>
      produce(state, (draft) => {
        switch (action.payload.rq_type) {
          case "temp":
            draft.inbox_member = action.payload.member;
            break;
          case "send":
            addMemberFB(state.inbox_member);
            break;
          default:
        }
      }),
    [UPDATE_LESSON]: (state, action) =>
      produce(state, (draft) => {
        switch (action.payload.rq_type) {
          case 0:
            break;

          default:
          // draft.inbox_member = action.payload.member;
        }
      }),
    [GET_ACCESS]: (state, action) =>
      produce(state, (draft) => {
        switch (action.payload.rq_type) {
          case "tgram":
            draft.access.tgram = action.payload.access;
            break;
          case "kakao":
            draft.access.kakao = action.payload.access;
            break;
          default:
            break;
        }
      }),
  },
  initialState
);

export const actionCreators = {
  getTutor,
  addTutor,
  getTutorFB,
  addTutorFB,
  getMemberFB,
  addMember,
  updateLessonFB,
  gen_access,
};
