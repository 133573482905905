import React from "react";
import styled from "styled-components";

const Button = (props) => {
  const {
    id,
    text,
    _onClick,
    _onMouseOver,
    _onMouseOut,
    is_float,
    children,
    margin,
    padding,
    width,
    height,
    bgColor,
  } = props;

  // console.log(" !!!!!!!!!!!!!!!!!!! \n", props);
  // console.log(props.bgColor);
  if (is_float) {
    return (
      <React.Fragment>
        <FloatButton onClick={_onClick}>{text ? text : children}</FloatButton>
      </React.Fragment>
    );
  }

  const styles = {
    margin: margin,
    width: width,
    height: height,
    padding: padding,
    background: bgColor,
    id: id,
  };

  return (
    <React.Fragment>
      <ElButton
        {...styles}
        onClick={_onClick}
        onMouseOver={_onMouseOver}
        onMouseOut={_onMouseOut}>
        {text ? text : children}
      </ElButton>
    </React.Fragment>
  );
};

Button.defaultProps = {
  text: false,
  children: null,
  _onClick: () => {},
  _onMouseOver: () => {},
  _onMouseOut: () => {},
  is_float: false,
  margin: false,
  width: "100%",
  padding: "12px 5px",
};

// &:hover: {
//   background-color: #ffffff;
// }

const ElButton = styled.button`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: #ffffff;
  background: #212121;
  padding: ${(props) => props.padding};
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")};
`;

const FloatButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: #212121;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 800;
  position: fixed;
  bottom: 50px;
  right: 16px;
  text-align: center;
  vertical-align: middle;
  border: none;
  border-radius: 50px;
`;

export default Button;
