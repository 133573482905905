import React, { useState } from "react";
import { Grid, Text, Button, Image, Input, Naver, Kakao } from "../elements";

import { history } from "../redux/configureStore";
import styled from "styled-components";

import logo from "../shared/mh_logo.gif";

const NotFound = (props) => {
  const tGram = () => {
    window.location.href = "https://t.me/MusicHome_bot";
  };
  const kTalk = () => {
    window.location.href = "http://pf.kakao.com/_zKZcK/chat";
  };

  return (
    <React.Fragment>
      {/* <Naver></Naver> */}
      <Kakao></Kakao>
      <center>
        <AspectOutter>
          <AspectInner src={logo}></AspectInner>
        </AspectOutter>
        <Text margin="0px" size="150%" bold>
          .....잘못된 경로입니다
        </Text>
        <br />
        <Text margin="0px" size="110%" bold>
          수강에 어려움이 있으시면 아래 버튼을 클릭해주세요.
        </Text>
      </center>
      <Grid padding="5%">
        <Grid is_flex padding="0px">
          <Grid is_flex padding="0px" width="5%"></Grid>
          <Grid is_flex padding="5px">
            <ElButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 15px"
              onClick={tGram}>
              텔레그램 로그인
            </ElButton>
            <ElButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 15px"
              onClick={kTalk}>
              카카오톡 상담
            </ElButton>
            <ElButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 15px"
              onClick={() => {
                props.history.goBack();
              }}>
              뒤로 가기
            </ElButton>
          </Grid>
          <Grid is_flex padding="0px" width="5%"></Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const ElButton = styled.button`
  ${(props) => (props.width ? `width: ${props.width};` : "width: 100%")};
  height: ${(props) => props.height};
  color: white;
  font-size: 17px;
  font-weight: bold;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")};
  background-color: ${(props) => props.bg};
  box-sizing: border-box;
  border: none;
  border-radius: 7px 25px 10px;
`;

const AspectOutter = styled.div`
  width: 50%;
  padding: 10%;
`;

const AspectInner = styled.div`
  background-position: center;
  padding: 20%;
  background-image: url("${(props) => props.src}");
  background-size: contain;
  background-repeat: no-repeat;
`;

export default NotFound;
