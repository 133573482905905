import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as testActions } from "../redux/modules/work";

import { useLocation } from "react-router-dom";
import Spinner from "../elements/Spinner2";

import { sha256 } from "js-sha256";
const { host, protocol } = window.location;
const lessonURL = `${protocol}//${host}/lesson/`;

const OAuth = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  // const [insName, setInsName] = useState("");

  const rcvInfo = useSelector((state) => state.work);

  const location = useLocation();

  const { Kakao } = window;

  React.useEffect(() => {
    if (rcvInfo.loading === true) {
      console.log("=====rcvinfo");
      console.log(rcvInfo);
      if (rcvInfo.inbox_member.k_register) {
        sessionStorage.setItem("rq_type", "kakao_complete");
      }
      const rq_type = sessionStorage.getItem("rq_type");
      switch (rq_type) {
        case "kakao_reg":
          dispatch(testActions.gen_access(rcvInfo, "kakao_reg"));
          break;
        case "kakao_complete":
          window.location.href = lessonURL + rcvInfo.inbox_member.tID;
          break;
        default:
          console.log("THIS CASE");
          return "none";
      }
    } else if (rcvInfo.inbox_member === "데이타가 없습니다") {
      console.log("tID 정보가 일치하지 않는 경우");
    }
  }, [rcvInfo]);

  const getToken = () => {
    if (location.pathname === "/auth_kakao") {
      const code = new URL(window.location.href).searchParams.get("code");
      // console.log(code);

      Kakao.Auth.login({
        success: function () {
          Kakao.API.request({
            url: "/v2/user/me",
            data: {
              property_keys: [
                "kakao_account.profile",
                "kakao_account.email",
                "kakao_account.birthday",
              ],
            },
            success: function (res) {
              // console.log(res);
              // console.log(props);
              const tid = sessionStorage.getItem("tid");

              dispatch(testActions.gen_access(res, "kakao"));
              dispatch(testActions.gen_access(tid, "tgram"));

              if (tid !== "undefined" && tid.length > 5) {
                dispatch(testActions.getMemberFB(tid));
              } else {
                // console.log(sha256.hmac("mhome", "5058612116"));
                // console.log(sha256.hmac("mhome", "505861216"));
                // console.log(sha256.hmac("mhome1", "505861216"));
                // console.log(sha256("505861216"));

                dispatch(testActions.getMemberFB(res.id, 2));
              }

              // history.goBack();
            },
            fail: function (err) {
              console.log(err);
            },
          });
        },
        fail: function (error) {
          window.alert("카카오 로그인 오류");
          console.log(error);
          //   alert(JSON.stringify(err));
        },
      });
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <React.Fragment>
      <center>
        <div>... 잠시만 기다려주세요</div>
        <br />
      </center>

      <Spinner></Spinner>
    </React.Fragment>
  );
};

export default OAuth;
