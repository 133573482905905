import React from "react";
import { Grid, Text, Button } from "../elements";
import { getCookie, deleteCookie } from "../shared/Cookie";

import { useSelector, useDispatch } from "react-redux";
import { actionCreators as userActions } from "../redux/modules/user";

import { history } from "../redux/configureStore";
import { apiKey } from "../shared/firebase";

import { Permit } from "../shared/Permit";

import logo from "../shared/mh_logo.gif";
import styled from "styled-components";

const Header = (props) => {
  // const [is_login, setIsLogin] = React.useState(false);

  // React.useEffect(() => {
  //   // 쿠키를 가져와요!
  //   let cookie = getCookie("쿠키 이름 넣기!");
  //   // 확인해봅시다!
  //   console.log(cookie);
  //   // 쿠키가 있으면?
  //   if (cookie) {
  //     setIsLogin(true);
  //   } else {
  //     setIsLogin(false);
  //   }
  // });

  const dispatch = useDispatch();
  const is_login = useSelector((state) => state.user.is_login);

  const _session_key = `firebase:authUser:${apiKey}:[DEFAULT]`;
  const is_session = sessionStorage.getItem(_session_key) ? true : false;

  // return (
  //   <Permit>
  //     <React.Fragment>
  //       <Grid is_flex padding="4px 16px">
  //         <Grid>
  //           <Text margin="0px" size="24px" bold>
  //             헬로
  //           </Text>
  //         </Grid>

  //         <Grid is_flex>
  //           <Button text="내정보"></Button>
  //           <Button text="알림"></Button>
  //           <Button
  //             text="로그아웃"
  //             _onClick={() => {
  //               deleteCookie("user_id");
  //             }}
  //           ></Button>
  //         </Grid>
  //       </Grid>
  //     </React.Fragment>
  //   </Permit>
  // );

  if (is_login && is_session) {
    return (
      <React.Fragment>
        <Grid is_flex padding="4px 16px">
          <Grid>
            {/* <Text margin="0px" size="24px" bold>
              회원 헤더
            </Text> */}
            <HeaderLogo src={logo}></HeaderLogo>
          </Grid>

          <Grid is_flex>
            <MhButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 2px"
              onClick={() => {
                history.push("/manager/9697598");
              }}>
              관리자
            </MhButton>
            <MhButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 2px"
              onClick={() => {
                dispatch(userActions.logoutFB());
              }}>
              로그아웃
            </MhButton>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid is_flex padding="4px 16px">
          <Grid>
            <HeaderLogo src={logo}></HeaderLogo>
          </Grid>

          <Grid is_flex>
            <MhButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 2px"
              onClick={() => {
                history.push("/login");
              }}>
              로그인
            </MhButton>
            <MhButton
              height="50px"
              bg="#DF5E5E"
              margin="0px 2px"
              onClick={() => {
                history.push("/signup");
              }}>
              회원가입
            </MhButton>

            {/* <Button
              text="로그인"
              _onClick={() => {
                history.push("/login");
              }}></Button>
            <Button
              text="회원가입"
              _onClick={() => {
                history.push("/signup");
              }}></Button> */}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

const HeaderLogo = styled.div`
  background-position: left;
  margin: 2%;
  padding: 15%;
  background-image: url("${(props) => props.src}");
  background-size: contain;
  background-repeat: no-repeat;
`;

const MhButton = styled.button`
  ${(props) => (props.width ? `width: ${props.width};` : "width: 100%")};
  height: ${(props) => props.height};
  color: white;
  font-size: 17px;
  font-weight: bold;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")};
  background-color: ${(props) => props.bg};
  box-sizing: border-box;
  border: none;
  border-radius: 7px 25px 10px;
`;

Header.defaultProps = {};

export default Header;
