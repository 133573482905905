import React, { useState } from "react";
import { Grid, Text, Button, Image, Input } from "../elements";
import VideoPlayer from "../components/VideoPlayer";

import { useSelector, useDispatch } from "react-redux";
import { actionCreators as testActions } from "../redux/modules/work";
import { history } from "../redux/configureStore";
import vData from "../shared/test.json";
import styled from "styled-components";

import logo from "../shared/mh_logo.gif";

// import CommentList from "../components/CommentList";
// import CommentWrite from "../components/CommentWrite";

// https://colorhunt.co/palette/fdd2bfe98580df5e5e492f10

const Lesson = (props) => {
  const dispatch = useDispatch();

  const [lessonNumber, setLessonNumber] = useState(1);
  const [lessonLink, setLessonLink] = useState("");

  const [bgColor1, setBgColor1] = useState(`#DF5E5E`);
  const [bgColor2, setBgColor2] = useState(`#DF5E5E`);
  const [btn_bgColor, setBtn_bgColor] = useState([
    `#DF5E5E`,
    `#DF5E5E`,
    `#DF5E5E`,
    `#DF5E5E`,
    `#DF5E5E`,
    `#DF5E5E`,
    `#DF5E5E`,
  ]);
  const [replayBtnText, setReplayBtnText] = useState("복습하기");
  const [insName, setInsName] = useState("");
  const [lsnInfo, setLsnInfo] = useState("");

  const [confirmBtn, setConfirmBtn] = useState(false);

  const rcvInfo = useSelector((state) => state.work);
  console.log("▶ 렌더링", rcvInfo);
  // console.log("▶ 레슨링크", lessonLink);

  // 주소에서 tID 수신
  const member_id = props.match.params.member_code;

  const tutor_list = [
    "0",
    "합주",
    "바이올린",
    "첼로",
    "플룻",
    "클라리넷",
    "색소폰",
    "기타",
    "우쿨렐레",
    "드럼/카혼",
    "바이올린",
  ];

  const btn_text_list = {
    orch: "합주영상",
    switch_ins: "다른악기",
    replay: replayBtnText,
    progUpdate: "진도변경",
    confirm: { yes: "네, 변경합니다.", no: "아니오, 유지합니다." },
  };

  const btn_array = [
    btn_text_list.orch,
    btn_text_list.switch_ins,
    btn_text_list.replay,
    btn_text_list.progUpdate,
    btn_text_list.confirm.yes,
    btn_text_list.confirm.no,
  ];

  const getLessonFromID = (lesson_id) => {
    const tutor = Math.floor(lesson_id / 1000);
    const sq = lesson_id - 1000 * Math.floor(lesson_id / 1000);
    const sm = Math.ceil(sq / 32);
    const wk = sq - 32 * (sm - 1);
    return {
      tutor: tutor,
      semester: sm,
      week: wk,
      sequence: sq,
    };
  };

  React.useEffect(() => {
    if (lessonNumber === 1) {
      setInsName(tutor_list[rcvInfo.inbox_member.i1c_tutor]);
    }
    const lsn0 = getLessonFromID(rcvInfo.lesson_info.orch?.id);
    const lsn1 = getLessonFromID(rcvInfo.lesson_info.ins1.id);
    const lsn1_last = getLessonFromID(rcvInfo.lesson_info.ins1_last.id);
    const lsn2 = getLessonFromID(rcvInfo.lesson_info.ins2.id);
    const lsn2_last = getLessonFromID(rcvInfo.lesson_info.ins2_last.id);
    const lsn3 = getLessonFromID(rcvInfo.lesson_info.ins3?.id);
    const lsn3_last = getLessonFromID(rcvInfo.lesson_info.ins3_last?.id);
    const lsn4 = getLessonFromID(rcvInfo.lesson_info.ins4?.id);
    const lsn4_last = getLessonFromID(rcvInfo.lesson_info.ins4_last?.id);

    setLsnInfo({
      lsn0: lsn0,
      lsn1: lsn1,
      lsn10: lsn1_last,
      lsn2: lsn2,
      lsn20: lsn2_last,
      lsn3: lsn3,
      lsn30: lsn3_last,
      lsn4: lsn4,
      lsn40: lsn4_last,
    });
  }, [rcvInfo]);

  React.useEffect(() => {
    if (member_id.length > 5) {
      dispatch(testActions.getMemberFB(member_id));
      console.log("▶ mID 길이 > 5", member_id);
    }
    console.log("▶ mID 업데이트", member_id);
  }, []);

  const lesson_select = (e) => {
    const iText = e.target.innerText;
    let btn_number = 0;
    if (iText === btn_text_list.orch) {
      btn_number = 1;
    } else if (iText === btn_text_list.switch_ins) {
      btn_number = 2;
    } else if (iText === btn_text_list.progUpdate) {
      btn_number = 3;
    } else if (iText === btn_text_list.replay) {
      btn_number = 4;
    } else if (iText === btn_text_list.confirm.yes) {
      btn_number = 5;
    } else if (iText === btn_text_list.confirm.no) {
      btn_number = 6;
    }

    if (btn_number <= 1) {
      setReplayBtnText("복습하기");
    }

    const lessonProg = [
      rcvInfo.inbox_member.i0u_updated,
      rcvInfo.inbox_member.i1u_updated,
      rcvInfo.inbox_member.i2u_updated,
    ];
    const lessonID = [
      rcvInfo.inbox_member.oProg,
      rcvInfo.inbox_member.i1a_lesson,
      rcvInfo.inbox_member.i2a_lesson,
    ];

    switch (btn_number) {
      case 1:
        setLessonNumber(0);
        lesson_feeder(0);
        break;

      case 2:
        let tmp_nLsn = lessonNumber + 1;
        console.log(tmp_nLsn, "<<<");
        if (lessonNumber <= 1) {
          setLessonNumber(tmp_nLsn);
          setReplayBtnText("복습하기");
          lesson_feeder(tmp_nLsn);
        } else if (tmp_nLsn >= rcvInfo.lesson_info.numberOfInst) {
          setLessonNumber(1);
          setReplayBtnText("복습하기");
          lesson_feeder(1);
        } else {
          window.alert(
            "Unknown lesson_select: ",
            tmp_nLsn,
            rcvInfo.lesson_info.numberOfInst
          );
        }

        break;

      case 3:
        setBgColor1(`#F08FC0`);

        if (lessonProg[lessonNumber]) {
          window.alert("이번 주 진도를 이미 변경하셨습니다.");
        } else if (lessonNumber >= 10) {
          window.alert(
            "복습 영상 진도는 변경할 수 없습니다.\n[돌아가기]를 눌러서 이번 주 레슨을 선택하고 진도를 변경해주세요."
          );
        } else {
          const nextProg = lessonID[lessonNumber] + 1;
          console.log(rcvInfo.member_doc_id, nextProg, lessonNumber);
          window.alert(
            `진도를 다음으로 변경합니다.\n다음레슨 : ${
              lessonID[lessonNumber] + 1
            }\n이대로 진행해도 좋습니까?
            \n확인을 위해 "네, 아니오"를 선택해주세요.`
          );

          // FB에 진도 업데이트 하기
          setConfirmBtn(true);
        }

        break;

      case 4:
        switch (lessonNumber) {
          case 1:
            setLessonNumber(10);
            setReplayBtnText("돌아가기");
            lesson_feeder(10);
            break;
          case 2:
            setLessonNumber(20);
            setReplayBtnText("돌아가기");
            lesson_feeder(20);
            break;
          case 10:
            setLessonNumber(1);
            setReplayBtnText("복습하기");
            lesson_feeder(1);
            break;
          case 20:
            setLessonNumber(2);
            setReplayBtnText("복습하기");
            lesson_feeder(2);
            break;
          default:
            window.alert("합주 영상은 복습이 제공되지 않습니다.");
        }
        // setBgColor2(bgColor2 === `#DF5E5E` ? `#F08FC0` : `#DF5E5E`);
        break;
      case 5:
        const nextProg = lessonID[lessonNumber] + 1;
        console.log(rcvInfo.member_doc_id, nextProg, lessonNumber);
        dispatch(
          testActions.updateLessonFB(
            props,
            rcvInfo.member_doc_id,
            nextProg,
            lessonNumber,
            rcvInfo.inbox_member.tID
          )
        );
        // setConfirmBtn(false);
        // setLessonNumber(lessonNumber);

        break;
      case 6:
        setConfirmBtn(false);
        break;

      default:
    }
  };

  const lesson_feeder = (lesson) => {
    switch (lesson) {
      case 0:
        setInsName(tutor_list[1]);
        setLessonLink(
          rcvInfo.lesson_info.orch.vimeo &&
            rcvInfo.lesson_info.orch.vimeo !== ""
            ? rcvInfo.lesson_info.orch.vimeo
            : rcvInfo.lesson_info.orch.url
        );
        break;
      case 1:
        setInsName(tutor_list[rcvInfo.inbox_member.i1c_tutor]);
        setLessonLink(
          rcvInfo.lesson_info.ins1.vimeo &&
            rcvInfo.lesson_info.ins1.vimeo !== ""
            ? rcvInfo.lesson_info.ins1.vimeo
            : rcvInfo.lesson_info.ins1.url
        );
        break;
      case 2:
        setInsName(tutor_list[rcvInfo.inbox_member.i2c_tutor]);
        setLessonLink(
          rcvInfo.lesson_info.ins2.vimeo &&
            rcvInfo.lesson_info.ins2.vimeo !== ""
            ? rcvInfo.lesson_info.ins2.vimeo
            : rcvInfo.lesson_info.ins2.url
        );
        break;
      case 10:
        // console.log("GEE _set 10 Orig");
        // console.log(
        //   rcvInfo.lesson_info.ins1_last.vimeo,
        //   rcvInfo.lesson_info.ins1_last.url,
        //   lessonLink
        // );
        setInsName(tutor_list[rcvInfo.inbox_member.i1c_tutor]);
        setLessonLink(
          rcvInfo.lesson_info.ins1_last.vimeo &&
            rcvInfo.lesson_info.ins1_last.vimeo !== ""
            ? rcvInfo.lesson_info.ins1_last.vimeo
            : rcvInfo.lesson_info.ins1_last.url
        );
        break;
      case 20:
        setInsName(tutor_list[rcvInfo.inbox_member.i2c_tutor]);
        setLessonLink(
          rcvInfo.lesson_info.ins2_last.vimeo &&
            rcvInfo.lesson_info.ins2_last.vimeo !== ""
            ? rcvInfo.lesson_info.ins2_last.vimeo
            : rcvInfo.lesson_info.ins2_last.url
        );
        break;
      default:
    }
  };

  const mouseDown = (e) => {
    let color_state = [];
    btn_array.map((i, idx) => {
      if (i === e.target.innerText) {
        return color_state.push(`#F08FC0`);
      }
      return color_state.push(`#DF5E5E`);
    });
    setBtn_bgColor(color_state);
  };

  const mouseUp = (e) => {
    let color_state = [];
    btn_array.map((i, idx) => {
      return color_state.push(`#DF5E5E`);
    });
    setBtn_bgColor(color_state);
  };

  const tGram = () => {
    window.location.href = "https://t.me/MusicHome_bot";
  };
  const kTalk = () => {
    window.location.href = "http://pf.kakao.com/_zKZcK/chat";
  };

  // console.log(props.match.params.member_code);
  // url param
  //  1. member_ident = user_name
  //  2. video_url
  //  lecture, youtube, vimeo

  // 강의 링크 조작부

  return (
    <React.Fragment>
      {rcvInfo.loading === true ? (
        <React.Fragment>
          <Grid padding="16px">
            <center>
              <Text margin="0px" size="150%" bold>
                {rcvInfo.inbox_member.name
                  ? rcvInfo.inbox_member.name
                  : "뮤직홈"}
                &nbsp;{rcvInfo.inbox_member.oProg ? "담당자" : "회원"}님,
                반갑습니다!
              </Text>
              <br />

              {!rcvInfo.inbox_member.name ? (
                <Text margin="0px" size="120%" bold>
                  &nbsp;&nbsp;&nbsp;강의가 표시되지 않나요? 텔레그램에서
                  로그인을 해주세요.
                </Text>
              ) : null}

              {rcvInfo.inbox_member.name && lessonNumber === 0 ? (
                <Text margin="0px" size="120%" bold>
                  {tutor_list[1]}&nbsp;
                  {lsnInfo.lsn0.semester}학기 {lsnInfo.lsn0.week}
                  주차 영상입니다.
                </Text>
              ) : null}
              {rcvInfo.inbox_member.name && lessonNumber === 1 ? (
                <Text margin="0px" size="120%" bold>
                  {tutor_list[lsnInfo.lsn1.tutor]}&nbsp;
                  {lsnInfo.lsn1.semester}학기 {lsnInfo.lsn1.week}
                  주차 레슨입니다.
                </Text>
              ) : null}
              {rcvInfo.inbox_member.name && lessonNumber === 2 ? (
                <Text margin="0px" size="120%" bold>
                  {tutor_list[lsnInfo.lsn2.tutor]}&nbsp;
                  {lsnInfo.lsn2.semester}학기 {lsnInfo.lsn2.week}
                  주차 레슨입니다.
                </Text>
              ) : null}
              {rcvInfo.inbox_member.name && lessonNumber === 10 ? (
                <Text margin="0px" size="120%" bold>
                  {tutor_list[lsnInfo.lsn10.tutor]}&nbsp;
                  {lsnInfo.lsn10.semester}학기 {lsnInfo.lsn10.week}
                  주차 레슨입니다.
                </Text>
              ) : null}
              {rcvInfo.inbox_member.name && lessonNumber === 20 ? (
                <Text margin="0px" size="120%" bold>
                  {tutor_list[lsnInfo.lsn20.tutor]}&nbsp;
                  {lsnInfo.lsn20.semester}학기 {lsnInfo.lsn20.week}
                  주차 레슨입니다.
                </Text>
              ) : null}

              <br />
              <Text margin="0px" size="120%" bold>
                &nbsp;&nbsp;&nbsp;전체화면으로 시청하세요!
              </Text>
              {rcvInfo.inbox_member.oProg &&
              (rcvInfo.lesson_info.orch === "") |
                (rcvInfo.inbox_member.i1a_lesson > 1000) &&
              (rcvInfo.lesson_info.ins1 === "") |
                (rcvInfo.inbox_member.i2a_lesson > 1000) &&
              rcvInfo.lesson_info.ins2 === "" ? (
                <React.Fragment>
                  <br />
                  <Text margin="0px" size="120%" bold>
                    &nbsp;&nbsp;&nbsp;레슨 영상 관련 오류가 있습니다. 관리자에게
                    문의해주세요.{" "}
                    <a href="http://pf.kakao.com/_zKZcK/chat">
                      ▶ 카카오톡 연결
                    </a>
                  </Text>
                </React.Fragment>
              ) : null}
            </center>
            {lessonLink ? (
              <VideoPlayer lecture_url={lessonLink} />
            ) : (
              <VideoPlayer
                // user_name={rcvInfo.inbox_member.name}
                // user_code={props.match.params.member_code}
                lecture_url={
                  rcvInfo.lesson_info.ins1.vimeo !== undefined &&
                  rcvInfo.lesson_info.ins1.vimeo !== ""
                    ? rcvInfo.lesson_info.ins1.vimeo
                    : rcvInfo.lesson_info.ins1.url
                }
              />
            )}
            {!confirmBtn ? (
              <Grid is_flex padding="0px">
                {rcvInfo.inbox_member.oProg &&
                rcvInfo.lesson_info.orch !== "" &&
                lessonNumber !== 0 ? (
                  <ElButton
                    onClick={lesson_select}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    // text="오케스트라&#10;합주영상"
                    bg={btn_bgColor[0]}
                    margin="0px 2px"
                    height="50px">
                    {btn_text_list.orch}
                  </ElButton>
                ) : null}
                {lessonNumber === 0 || rcvInfo.inbox_member.i2a_lesson ? (
                  <ElButton
                    onClick={lesson_select}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    margin="0px 2px"
                    height="50px"
                    bg={btn_bgColor[1]}>
                    {btn_text_list.switch_ins}
                  </ElButton>
                ) : null}
                {rcvInfo.inbox_member.name && lessonNumber !== 0 ? (
                  <ElButton
                    onClick={lesson_select}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    height="50px"
                    bg={btn_bgColor[2]}
                    margin="0px 2px">
                    {btn_text_list.replay}
                  </ElButton>
                ) : null}
                {rcvInfo.inbox_member.name ? (
                  <ElButton
                    onClick={lesson_select}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    height="50px"
                    bg={btn_bgColor[3]}
                    margin="0px 2px">
                    {btn_text_list.progUpdate}
                  </ElButton>
                ) : null}
              </Grid>
            ) : (
              <Grid is_flex padding="0px">
                <Grid is_flex padding="0px" width="5%"></Grid>
                <Grid is_flex padding="5px">
                  <ElButton
                    height="50px"
                    bg={btn_bgColor[4]}
                    margin="0px 2px"
                    onClick={lesson_select}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    // onMouseOver={onMouse}
                    // onMouseOut={onMouse}>
                  >
                    {btn_text_list.confirm.yes}
                  </ElButton>
                  <ElButton
                    height="50px"
                    bg={btn_bgColor[5]}
                    margin="0px 2px"
                    onClick={lesson_select}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    // onMouseOver={onMouse}
                    // onMouseOut={onMouse}>
                  >
                    {btn_text_list.confirm.no}
                  </ElButton>
                </Grid>
                <Grid is_flex padding="0px" width="5%"></Grid>
              </Grid>
            )}
          </Grid>
          {/* <button
            onClick={() => {}}
            onMouseDown={() => {
              console.log("마우스다운");
            }}>
            테스트용 버튼입니다. 눌러보세요.
          </button> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <center>
            <AspectOutter>
              <AspectInner src={logo}></AspectInner>
            </AspectOutter>
            <Text margin="0px" size="150%" bold>
              .....잠시만 기다려주세요
            </Text>
            <br />
            <Text margin="0px" size="110%" bold>
              강의가 표시되지 않는다면 아래 버튼을 클릭해주세요.
            </Text>
          </center>
          <Grid padding="5%">
            <Grid is_flex padding="0px">
              <Grid is_flex padding="0px" width="5%"></Grid>
              <Grid is_flex padding="5px">
                <ElButton
                  height="50px"
                  bg="#DF5E5E"
                  margin="0px 15px"
                  onClick={tGram}>
                  텔레그램 로그인
                </ElButton>
                <ElButton
                  height="50px"
                  bg="#DF5E5E"
                  margin="0px 15px"
                  onClick={kTalk}>
                  카카오톡 상담
                </ElButton>
                <ElButton
                  height="50px"
                  bg="#DF5E5E"
                  margin="0px 15px"
                  onClick={() => {
                    props.history.goBack();
                  }}>
                  뒤로 가기
                </ElButton>
              </Grid>
              <Grid is_flex padding="0px" width="5%"></Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ElButton = styled.button`
  ${(props) => (props.width ? `width: ${props.width};` : "width: 100%")};
  height: ${(props) => props.height};
  color: white;
  font-size: 17px;
  font-weight: bold;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")};
  background-color: ${(props) => props.bg};
  box-sizing: border-box;
  border: none;
  border-radius: 7px 25px 10px;
  /* border-radius: 7px 25px 10px; */
`;

const AspectOutter = styled.div`
  width: 50%;
  padding: 10%;
`;

const AspectInner = styled.div`
  background-position: center;
  padding: 20%;
  background-image: url("${(props) => props.src}");
  background-size: contain;
  background-repeat: no-repeat;
`;

export default Lesson;
