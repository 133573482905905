import React from "react";
import styled from "styled-components";

import { Text, Grid } from "./index";

const Select = (props) => {
  const {
    label,
    placeholder,
    _onChange,
    type,
    margin,
    width,
    id,
    lessonDB,
    memberDB,
    noGrid,
  } = props;

  if (memberDB) {
    return (
      <React.Fragment>
        <WSelect onChange={_onChange}>
          <option value="-1">강사를 선택하세요</option>
          <option value="1000">Cd 지휘자</option>
          <option value="2000">Vn 최경화</option>
          <option value="3000">Vc 민다홍</option>
          <option value="4000">Fl 최고은</option>
          <option value="5000">Cl 한다현</option>
        </WSelect>
        <WSelect onChange={_onChange}>
          <option value="-1">학기를 선택하세요</option>
          <option value="0">1학기</option>
          <option value="32">2학기</option>
        </WSelect>
        <WSelect onChange={_onChange}>
          <option value="-1">주차를 선택하세요</option>
          <option value="1">1주차</option>
          <option value="2">2주차</option>
          <option value="3">3주차</option>
          <option value="4">4주차</option>
        </WSelect>
      </React.Fragment>
    );
  }

  if (lessonDB) {
    return (
      <React.Fragment>
        {label && (
          <Text margin="0px" bold>
            {label}
          </Text>
        )}
        <WSelect onChange={_onChange} width={width} id={id} margin={margin}>
          <option value="-1">강사를 선택하세요</option>
          <option value="1000">Cd 지휘자</option>
          <option value="2000">Vn 최경화</option>
          <option value="3000">Vc 민다홍</option>
          <option value="4000">Fl 최고은</option>
          <option value="5000">Cl 한다현</option>
          <option value="6000">Sax 최해민</option>
          <option value="7000">Gt 김경준</option>
          <option value="8000">Ukul 김두영</option>
          <option value="9000">Dr 윤성훈</option>
          <option value="10000">Vn 노현주</option>
        </WSelect>
        <WSelect onChange={_onChange} width={width} id={id} margin={margin}>
          <option value="-1">학기를 선택하세요</option>
          <option value="0">1학기</option>
          <option value="32">2학기</option>
          <option value="64">3학기</option>
          <option value="96">4학기</option>
          <option value="128">5학기</option>
          <option value="160">6학기</option>
          <option value="192">7학기</option>
          <option value="224">8학기</option>
          <option value="256">9학기</option>
          <option value="288">10학기</option>
        </WSelect>
        <WSelect onChange={_onChange} width={width} id={id} margin={margin}>
          <option value="-1">주차를 선택하세요</option>
          <option value="1">1주차</option>
          <option value="2">2주차</option>
          <option value="3">3주차</option>
          <option value="4">4주차</option>
          <option value="5">5주차</option>
          <option value="6">6주차</option>
          <option value="7">7주차</option>
          <option value="8">8주차</option>
          <option value="9">9주차</option>
          <option value="10">10주차</option>
          <option value="11">11주차</option>
          <option value="12">12주차</option>
          <option value="13">13주차</option>
          <option value="14">14주차</option>
          <option value="15">15주차</option>
          <option value="16">16주차</option>
          <option value="17">17주차</option>
          <option value="18">18주차</option>
          <option value="19">19주차</option>
          <option value="20">20주차</option>
          <option value="21">21주차</option>
          <option value="22">22주차</option>
          <option value="23">23주차</option>
          <option value="24">24주차</option>
          <option value="25">25주차</option>
          <option value="26">26주차</option>
          <option value="27">27주차</option>
          <option value="28">28주차</option>
          <option value="29">29주차</option>
          <option value="30">30주차</option>
          <option value="31">31주차</option>
          <option value="32">32주차</option>
        </WSelect>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid>
        {label && <Text margin="0px">{label}</Text>}
        <WSelect>
          <option value="0">선택하세요</option>
          <option value="1">1학기</option>
          <option value="2">2학기</option>
          <option value="3">3학기</option>
        </WSelect>
      </Grid>
    </React.Fragment>
  );
};

Select.defaultProps = {
  label: false,
  width: "25%",
  placeholder: "텍스트를 입력해주세요.",
  type: "text",
  _onChange: () => {},
};

const WSelect = styled.select`
  border: 1px solid #212121;
  padding: 12px 4px;
  box-sizing: border-box;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;
// ${(props) => (props.width ? `width: ${props.width};` : "width: 25%")}

export default Select;
