// var express = require("express");
// var app = express();
// var state = "RANDOM_STATE";
// var redirectURI = encodeURI("https://google.com");
// var api_url = "";
// app.get("/naverlogin", function (req, res) {
//   api_url =
//     "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=" +
//     client_id +
//     "&redirect_uri=" +
//     redirectURI +
//     "&state=" +
//     state;
//   res.writeHead(200, { "Content-Type": "text/html;charset=utf-8" });
//   res.end(
//     "<a href='" +
//       api_url +
//       "'><img height='50' src='http://static.nid.naver.com/oauth/small_g_in.PNG'/></a>"
//   );
// });
// app.get("/callback", function (req, res) {
//   code = req.query.code;
//   state = req.query.state;
//   api_url =
//     "https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=" +
//     client_id +
//     "&client_secret=" +
//     client_secret +
//     "&redirect_uri=" +
//     redirectURI +
//     "&code=" +
//     code +
//     "&state=" +
//     state;
//   var request = require("request");
//   var options = {
//     url: api_url,
//     headers: {
//       "X-Naver-Client-Id": client_id,
//       "X-Naver-Client-Secret": client_secret,
//     },
//   };
//   request.get(options, function (error, response, body) {
//     if (!error && response.statusCode == 200) {
//       res.writeHead(200, { "Content-Type": "text/json;charset=utf-8" });
//       res.end(body);
//     } else {
//       res.status(response.statusCode).end();
//       console.log("error = " + response.statusCode);
//     }
//   });
// });
// app.listen(3000, function () {
//   console.log("http://127.0.0.1:3000/naverlogin app listening on port 3000!");
// });

// const { naver } = window as any;
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

var client_id = "9WjWJ86MQ3hpiA4xds0v";
var client_secret = "8G9ZV84C8Y";

// function Login(props: any) {
//   const initializeNaverLogin = () => {
//     const naverLogin = new naver.LoginWithNaverId({
//       clientId: "9WjWJ86MQ3hpiA4xds0v",
//       callbackUrl: "http://localhost:3000/auth",
//       isPopup: false, // popup 형식으로 띄울것인지 설정
//       loginButton: { color: 'white', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
//     });
//     naverLogin.init();
//   };

//   useEffect(() => {
//     initializeNaverLogin();
//   }, []);

//   return (
//     <div id='naverIdLogin' />
//   )
// }

const NaverLogin = () => {
  // const url = window.document.URL;
  const { host, protocol } = window.location;
  const callbackUrl = `${protocol}//${host}/auth2`;
  const naverLogin = () => {
    const naverlogin = new window.naver.LoginWithNaverId({
      clientId: client_id,
      callbackUrl: callbackUrl,
      isPopup: false, // popup 형식으로 띄울것인지 설정
      loginButton: { color: "white", type: 1, height: "47" }, //버튼의 스타일, 타입, 크기를 지정
    });
    naverlogin.init();
    naverlogin.getLoginStatus((status) => {
      if (status) {
        window.alert("로그인 O");
        console.log(status);
      } else {
        window.alert("로그인 X");
      }
    });
  };

  const location = useLocation();

  const getNaverToken = () => {
    if (!location.hash) return;
    const token = location.hash.split("access_token=")[1].split("&")[0];
    const state = location.hash.split("state=")[1].split("&")[0];

    const rqst =
      "https://nid.naver.com/oauth2.0/token?client_id=" +
      client_id +
      "&client_secret=" +
      client_secret +
      "&grant_type=authorization_code&state=" +
      state +
      "&code=" +
      token;

    window.alert(rqst);
    const header = "Bearer " + token;

    // fetch("https://api.npms.io/v2/search?q=react", {
    //   method: "get",
    // })
    //   .then((res) => console.log(res))
    //   .catch(console.log(1));

    fetch("https://openapi.naver.com/v1/nid/me", {
      method: "get",
      headers: {
        Authorization: header,
      },
    })
      .then((res) => console.log(res))
      .catch(console.log(1));

    window.location.href = rqst;
  };

  useEffect(() => {
    // window.location.href.includes("access_token");
    // const location = window.location.host.split("=")[1];
    // const sns_auth = location.split("&")[0];
    // console.log(sns_auth);

    naverLogin();
    getNaverToken();
  });
  return (
    <React.Fragment>
      <center>
        <div id="naverIdLogin" />
      </center>
    </React.Fragment>
  );
};

export default NaverLogin;
