import React, { useEffect } from "react";
import { Grid, Text, Button, Image, Input, Select } from "../elements";

import { useSelector, useDispatch } from "react-redux";
import { actionCreators as testActions } from "../redux/modules/work";
import styled from "styled-components";

import logo from "../shared/mh_logo.gif";

// import { history } from "../redux/configureStore";

import data from "../shared/test_orig_format.json";

const Manager = (props) => {
  const dispatch = useDispatch();
  const [tutor, setTutor] = React.useState(-1);
  const [semester, setSemester] = React.useState(-1);
  const [week, setWeek] = React.useState(-1);
  const [id, setId] = React.useState("");
  const [memberSend, set_memberSend] = React.useState("");
  const [duplicate, set_duplicate] = React.useState("");

  const [autoIdState, set_autoIdState] = React.useState("");
  const [confirmBtn, setConfirmBtn] = React.useState(false);
  const [confirmMbrBtn, setConfirmMbrBtn] = React.useState(false);

  const [is_orch, set_is_orch] = React.useState(false);
  const [numberOfIns, set_numberOfIns] = React.useState(1);
  const [confirmSend, set_confirmSend] = React.useState("");

  const { history } = props;
  const rcvInfo = useSelector((state) => state.work);
  const is_login = useSelector((state) => state.user.is_login);

  useEffect(() => {
    console.log("여기");
    if (id > 1000) {
      dispatch(testActions.getTutorFB(id));
    }
  }, [id]);

  const inputText = {
    lessonID: "레슨ID",
    lessonNotes: "레슨 메모",
    youtube: "YouTube Link",
    vimeo: "Vimeo Link",
    mbr_name: "회원이름",
    mbr_mobile: "전화번호",
    mbr_dob: "생년월일 6자리",
    mbr_orch: "오케스트라",
    mbr_tID: "tID",
  };

  const tutor_list = [
    "0",
    "합주",
    "바이올린",
    "첼로",
    "플룻",
    "클라리넷",
    "색소폰",
    "기타",
    "우쿨렐레",
    "드럼/카혼",
    "바이올린",
  ];

  const mbrSend = {
    name: "",
    mobile: "",
    dob: "",
    orch_name: "",
    pURL: "",
    svcSTS: "",
    tID: "",
    token: "",
    ins0: {
      id: 0,
      lesson: 0,
      tutor: 0,
      name: "",
      semester: 0,
      week: 0,
      updated: false,
      updated_at: 0,
    },
    ins1: {
      id: 0,
      lesson: 0,
      tutor: 0,
      name: "",
      semester: 0,
      week: 0,
      updated: false,
      updated_at: 0,
    },
    ins2: {
      id: 0,
      lesson: 0,
      tutor: 0,
      name: "",
      semester: 0,
      week: 0,
      updated: false,
      updated_at: 0,
    },
    ins3: {
      id: 0,
      lesson: 0,
      tutor: 0,
      name: "",
      semester: 0,
      week: 0,
      updated: false,
      updated_at: 0,
    },
    ins4: {
      id: 0,
      lesson: 0,
      tutor: 0,
      name: "",
      semester: 0,
      week: 0,
      updated: false,
      updated_at: 0,
    },
  };

  const autoId = {
    tutor: 0,
    semester: 0,
    week: 0,
    id: 0,
    id_fx: "",
    notes: "",
    youtube: "",
    vimeo: "",
    new_entry: false,
  };

  const getLessonFromID = (lesson_id, rqType = 0) => {
    switch (rqType) {
      case 0:
        const tutor = Math.floor(lesson_id / 1000);
        const sq = lesson_id - 1000 * Math.floor(lesson_id / 1000);
        const sm = Math.ceil(sq / 32);
        const wk = sq - 32 * (sm - 1);
        console.log(lesson_id, tutor, sq, sm, wk);
        return {
          tutor: tutor,
          semester: sm,
          week: wk,
          sequence: sq,
        };
      case 1:
        break;
      default:
    }
  };

  useEffect(() => {
    console.log("++++++++++");
    console.log(rcvInfo);

    if (rcvInfo.inbox_member.length > 1) {
      window.alert(
        "검색결과에 복수 데이타가 있습니다.\n   ※ 중복: " +
          rcvInfo.inbox_member.length +
          "개"
      );

      const rcvInfoArray = [];
      // Key Prop 만들어주기
      rcvInfo.inbox_member.map((p, idx) => {
        return rcvInfoArray.push(p.name + " " + p.tID);
      });

      set_duplicate(rcvInfoArray);

      return;
    }
  }, [rcvInfo]);

  const showInsSelect = (e) => {
    const btnText = e.target.innerText;

    if (btnText === "[합주/지휘영상 ▼]") {
      const valueToChange = is_orch ? false : true;
      set_is_orch(valueToChange);
    } else if (btnText === "[수강악기 2 ▼]") {
      const valueToChange = numberOfIns === 1 ? 2 : 1;
      set_numberOfIns(valueToChange);
    } else if (btnText === "[수강악기 3 ▼]") {
      const valueToChange = numberOfIns === 2 ? 3 : 2;
      set_numberOfIns(valueToChange);
    } else if (btnText === "[수강악기 4 ▼]") {
      const valueToChange = numberOfIns === 3 ? 4 : 3;
      set_numberOfIns(valueToChange);
    } else {
    }
  };

  if (props.match.params.member_code !== "9697598") {
    window.alert("올바른 관리자가 아닙니다.");
    history.replace("/");
    return null;
  }

  const updateTutor = (value) => {
    setTutor(value);
  };

  const testData = {
    강사: "mean1",
  };

  const action_1 = () => {
    data.map((d) => {
      console.log(d);
      updateTutor(d);
      dispatch(testActions.addTutorFB(d));
      return null;
    });
  };
  // 강사 데이타 입력부
  const action_2 = () => {
    console.log("testData", testData);
    console.log("before", tutor);
    updateTutor(testData);
    console.log("after", tutor);
    dispatch(testActions.addTutorFB(tutor));
  };

  const action_3 = () => {
    return null;
    // updateTutor(d);
  };

  const changeContents = (e) => {
    console.log(e);
    console.log(e.target);
    setId(e.target.value);
  };

  // 선택값 변경

  const change_Member = (e) => {
    const input_value = e.target.value;
    if (e.target.placeholder === "회원이름") {
      const setData = {
        name: input_value,
        mobile: memberSend.mobile,
        ilink: memberSend.ilink,
      };
      set_memberSend(setData);
    } else if (e.target.placeholder === "전화번호") {
      const setData = {
        name: memberSend.name,
        mobile: input_value,
        ilink: memberSend.ilink,
      };
      set_memberSend(setData);
    } else if (e.target.placeholder === "레슨링크") {
      const setData = {
        name: memberSend.name,
        mobile: memberSend.mobile,
        ilink: input_value,
      };
      set_memberSend(setData);
    } else {
      window.alert("(!) 오류");
    }
  };

  const change_Lesson = (e) => {
    const optText = e.target[0].innerText;
    const optValue = e.target.value;

    if (optText.indexOf("강사") === 0) {
      setTutor(optValue);
    }
    if (optText.indexOf("학기") === 0) {
      setSemester(optValue);
    }
    if (optText.indexOf("주차") === 0) {
      setWeek(optValue);
    }
  };

  const getId = () => {
    console.log(">>> ", id, typeof id);
    if (Number(tutor) < 0 || Number(semester) < 0 || Number(week) < 0) {
      window.alert("모든 입력값을 선택하세요");
      return;
    }
    const result = Number(tutor) + Number(semester) + Number(week);
    setId(result);
    return;
  };

  // 강사 DB 새로입력
  const updateID = (e) => {
    const optValue = e.target.value;
    console.log(e.target);
    console.log(e.target.id, optValue, mbrSend);

    if (e.target.id === "ins0") {
      const optText = e.target[0].innerText;

      if (optText.indexOf("강사") === 0) {
        if (optValue === "1000") {
          mbrSend.ins0.tutor = optValue;
        } else {
          window.alert("합주 영상 강사는 지휘자만 선택 가능합니다.");
          mbrSend.ins0.tutor = "1000";
          e.target.value = "1000";
        }
      }
      if (optText.indexOf("학기") === 0) {
        mbrSend.ins0.semester = optValue;
      }
      if (optText.indexOf("주차") === 0) {
        mbrSend.ins0.week = optValue;
      }
    } else if (e.target.id === "ins1") {
      const optText = e.target[0].innerText;

      if (optText.indexOf("강사") === 0) {
        mbrSend.ins1.tutor = optValue;
      }
      if (optText.indexOf("학기") === 0) {
        mbrSend.ins1.semester = optValue;
      }
      if (optText.indexOf("주차") === 0) {
        mbrSend.ins1.week = optValue;
      }
    } else if (e.target.id === "ins2") {
      const optText = e.target[0].innerText;

      if (optText.indexOf("강사") === 0) {
        mbrSend.ins2.tutor = optValue;
      }
      if (optText.indexOf("학기") === 0) {
        mbrSend.ins2.semester = optValue;
      }
      if (optText.indexOf("주차") === 0) {
        mbrSend.ins2.week = optValue;
      }
    } else if (e.target.id === "ins3") {
      const optText = e.target[0].innerText;

      if (optText.indexOf("강사") === 0) {
        mbrSend.ins3.tutor = optValue;
      }
      if (optText.indexOf("학기") === 0) {
        mbrSend.ins3.semester = optValue;
      }
      if (optText.indexOf("주차") === 0) {
        mbrSend.ins3.week = optValue;
      }
    } else if (e.target.id === "ins4") {
      const optText = e.target[0].innerText;

      if (optText.indexOf("강사") === 0) {
        mbrSend.ins4.tutor = optValue;
      }
      if (optText.indexOf("학기") === 0) {
        mbrSend.ins4.semester = optValue;
      }
      if (optText.indexOf("주차") === 0) {
        mbrSend.ins4.week = optValue;
      }
    } else if (e.target.placeholder === inputText.lessonID) {
      autoId.id = optValue;
    } else if (e.target.placeholder === inputText.lessonNotes) {
      autoId.notes = optValue;
    } else if (e.target.placeholder === inputText.youtube) {
      autoId.youtube = optValue;
    } else if (e.target.placeholder === inputText.vimeo) {
      autoId.vimeo = optValue;
    } else if (e.target.placeholder === inputText.mbr_name) {
      mbrSend.name = optValue;
    } else if (e.target.placeholder === inputText.mbr_mobile) {
      mbrSend.mobile = optValue;
    } else if (e.target.placeholder === inputText.mbr_dob) {
      mbrSend.dob = optValue;
    } else if (e.target.placeholder === inputText.mbr_orch) {
      mbrSend.orch_name = optValue;
    } else if (e.target.placeholder === inputText.mbr_tID) {
      mbrSend.tID = optValue;
    } else {
      const optText = e.target[0].innerText;

      if (optText.indexOf("강사") === 0) {
        autoId.tutor = optValue;
      }
      if (optText.indexOf("학기") === 0) {
        autoId.semester = optValue;
      }
      if (optText.indexOf("주차") === 0) {
        autoId.week = optValue;
      }

      autoId.id =
        Number(autoId.tutor) + Number(autoId.semester) + Number(autoId.week);
      console.log(autoId);
    }
  };

  // 마우스 클릭
  const action_A = () => {
    if (
      memberSend.name === undefined &&
      memberSend.mobile === undefined &&
      memberSend.ilink === undefined
    ) {
      window.alert("입력값이 없습니다.");
    } else {
      set_duplicate("");
      dispatch(testActions.getMemberFB(memberSend, 1));
    }
  };

  const action_B = () => {
    getId();
  };

  const sendNewMember = (e) => {
    const btn_id = e.target?.id;
    console.log("ID는  ", btn_id);

    switch (btn_id) {
      case "confirmMember":
        dispatch(testActions.addMember(0, "send"));
        break;
      case "cancelMember":
        window.location.reload();
        break;
      default:
        console.log("=====Z=====");
        console.log(confirmSend);
        const i0_id =
          Number(mbrSend.ins0.tutor) +
          Number(mbrSend.ins0.semester) +
          Number(mbrSend.ins0.week);
        const i1_id =
          Number(mbrSend.ins1.tutor) +
          Number(mbrSend.ins1.semester) +
          Number(mbrSend.ins1.week);
        const i2_id =
          Number(mbrSend.ins2.tutor) +
          Number(mbrSend.ins2.semester) +
          Number(mbrSend.ins2.week);
        const i3_id =
          Number(mbrSend.ins3.tutor) +
          Number(mbrSend.ins3.semester) +
          Number(mbrSend.ins3.week);
        const i4_id =
          Number(mbrSend.ins4.tutor) +
          Number(mbrSend.ins4.semester) +
          Number(mbrSend.ins4.week);

        mbrSend.ins0.id = i0_id;
        mbrSend.ins1.id = i1_id;
        mbrSend.ins2.id = i2_id;
        mbrSend.ins3.id = i3_id;
        mbrSend.ins4.id = i4_id;

        const confirmSendText = {
          i0_text:
            i0_id !== 0
              ? "담당자: " +
                tutor_list[getLessonFromID(i0_id).tutor] +
                " " +
                getLessonFromID(i0_id).semester +
                "학기 " +
                getLessonFromID(i0_id).week +
                "주차 // "
              : "",
          i1_text:
            i1_id !== 0
              ? "악기 1: " +
                tutor_list[getLessonFromID(i1_id).tutor] +
                " " +
                getLessonFromID(i1_id).semester +
                "학기 " +
                getLessonFromID(i1_id).week +
                "주차"
              : "",
          i2_text:
            i2_id !== 0
              ? " // 악기 2: " +
                tutor_list[getLessonFromID(i2_id).tutor] +
                " " +
                getLessonFromID(i2_id).semester +
                "학기 " +
                getLessonFromID(i2_id).week +
                "주차"
              : "",
          i3_text:
            i3_id !== 0
              ? " // 악기 3: " +
                tutor_list[getLessonFromID(i3_id).tutor] +
                " " +
                getLessonFromID(i3_id).semester +
                "학기 " +
                getLessonFromID(i3_id).week +
                "주차"
              : "",
          i4_text:
            i4_id !== 0
              ? " // 악기 4: " +
                tutor_list[getLessonFromID(i4_id).tutor] +
                " " +
                getLessonFromID(i4_id).semester +
                "학기 " +
                getLessonFromID(i4_id).week +
                "주차"
              : "",
        };

        const confirmText =
          "♠ 입력내용 - " +
          confirmSendText.i0_text +
          confirmSendText.i1_text +
          confirmSendText.i2_text +
          confirmSendText.i3_text +
          confirmSendText.i4_text;

        // Redux로 보내기
        dispatch(testActions.addMember(mbrSend, "temp"));
        set_confirmSend(confirmText);

        setConfirmMbrBtn(true);
      // 선택 드롭박스 초기화
      //확인 인 경우 (send)로 보내기

      // autoId.id_fx = getLessonFromID(autoId.id);
      // autoId.new_entry = true;
      // set_autoIdState(autoId);
      // setConfirmBtn(true);
      // console.log(tutor, semester, week);
      // set_idTxt(autoId.id);
    }
  };

  const sendUpdateLink = () => {
    console.log("=====X=====");
    console.log(autoId);
    autoId.id_fx = getLessonFromID(autoId.id);
    autoId.new_entry = false;
    set_autoIdState(autoId);
    setConfirmBtn(true);
    // set_idTxt(autoId.id);
  };

  const sendNewLink = (e) => {
    const btn_id = e.target?.id;
    console.log(btn_id);

    switch (btn_id) {
      case "newEntryLink":
        console.log(autoId);
        autoId.id_fx = getLessonFromID(autoId.id);
        autoId.new_entry = true;
        // 리덕스에 데이타 저장
        dispatch(testActions.addTutor(autoId, "newTemp"));

        set_autoIdState(autoId);
        console.log(tutor, semester, week);
        setConfirmBtn(true);
        break;
      case "confirmLink":
        console.log(autoId);
        console.log(tutor, semester, week);
        dispatch(testActions.addTutorFB(rcvInfo.linkAdd, "newAdd"));

        // dispatch(testActions.addTutor("", "newAdd"));
        // set_idTxt(autoId.id);
        // 리덕스에 저장된 데이타 FB로 날리기
        setConfirmBtn(false);
        break;
      case "cancelLink":
        console.log(autoId);
        dispatch(testActions.addTutor("", "deleteTemp"));
        setConfirmBtn(false);
        // 페이지 refresh
        break;
      default:
        console.log("sendNewLink 버튼 해당없음");
    }
  };

  return (
    <React.Fragment>
      {is_login ? (
        <React.Fragment>
          {" "}
          <Grid padding="16px">
            <Text margin="0px" size="36px" bold>
              관리자 페이지
            </Text>
            <hr />
            <Button
              text="ACTION_01"
              width="50%"
              margin="3px"
              _onClick={action_1}></Button>
            <Button
              text="ACTION_02"
              width="50%"
              margin="3px"
              _onClick={action_2}></Button>
            <Button
              text="ACTION_03"
              width="50%"
              margin="3px"
              _onClick={action_3}></Button>
            <hr />
            <Grid padding="1% 3% 0%">
              <Text margin="0px" size="24px" bold>
                회원 DB
              </Text>
              <Grid>
                {/* <Select _onChange={change_Member} memberDB></Select> */}
                <Input
                  placeholder="회원이름"
                  width="25%"
                  _onChange={change_Member}
                  noGrid></Input>
                <Input
                  placeholder="전화번호"
                  width="25%"
                  _onChange={change_Member}
                  noGrid></Input>
                <Input
                  placeholder="레슨링크"
                  width="25%"
                  _onChange={change_Member}
                  noGrid></Input>
                <Button
                  text="조회"
                  width="20%"
                  margin="3px"
                  _onClick={action_A}></Button>
                <Text margin="20px 5px 10px" size="16px" bold>
                  ▶&nbsp;{rcvInfo.inbox_member.name}&nbsp;(
                  {rcvInfo.inbox_member.mobile})&nbsp;
                  {rcvInfo.inbox_member.orch_name}
                </Text>
                <Text margin="0px" size="16px">
                  　레슨 ID: {rcvInfo.inbox_member.i1a_lesson}
                  {" / "}
                  {rcvInfo.inbox_member.i1d_semester}학기{" "}
                  {rcvInfo.inbox_member.i1e_week}주차
                  <br />
                  　레슨 시작일 : {rcvInfo.inbox_member.begin}
                  <br />
                  　회원 ID: abc01234efg
                </Text>
                <br />
                {duplicate === "" ? null : (
                  <Text margin="0px" size="16px" bold>
                    ▽ 중복 데이타 확인
                  </Text>
                )}

                {duplicate === ""
                  ? null
                  : duplicate.map((p, idx) => {
                      return <Text>　(!) {p}</Text>;
                    })}
              </Grid>
            </Grid>

            <Grid padding="1% 3% 0%">
              <Text margin="0px" size="24px" bold>
                회원 DB 수정
              </Text>
              <Input
                placeholder={inputText.mbr_name}
                width="15%"
                _onChange={updateID}
                noGrid></Input>
              <Input
                placeholder={inputText.mbr_mobile}
                width="20%"
                _onChange={updateID}
                noGrid></Input>
              <Input
                placeholder={inputText.mbr_dob}
                width="20%"
                _onChange={updateID}
                noGrid></Input>
              <Input
                placeholder={inputText.mbr_orch}
                width="20%"
                _onChange={updateID}
                noGrid></Input>
              <Button
                text="기존 정보 수정"
                width="20%"
                margin="3px"
                _onClick={sendUpdateLink}></Button>
            </Grid>
            <Grid padding="1% 3% 0%">
              <Text margin="0px" size="24px" bold>
                회원 DB 새로입력
              </Text>
              <Grid>
                <Input
                  placeholder={inputText.mbr_name}
                  width="15%"
                  _onChange={updateID}
                  noGrid></Input>
                <Input
                  placeholder={inputText.mbr_mobile}
                  width="15%"
                  _onChange={updateID}
                  noGrid></Input>
                <Input
                  placeholder={inputText.mbr_dob}
                  width="15%"
                  _onChange={updateID}
                  noGrid></Input>
                <Input
                  placeholder={inputText.mbr_orch}
                  width="15%"
                  _onChange={updateID}
                  noGrid></Input>
                <Input
                  placeholder={inputText.mbr_tID}
                  width="15%"
                  _onChange={updateID}
                  noGrid></Input>
                <Button
                  text="입력내용확인"
                  width="20%"
                  margin="3px"
                  _onClick={sendNewMember}></Button>
              </Grid>
              <Grid margin="0px 0px 10px 15px">
                <Text bold _onClick={showInsSelect}>
                  [합주/지휘영상 ▼]
                </Text>
                {is_orch ? (
                  <Select
                    id="ins0"
                    _onChange={updateID}
                    width="25%"
                    lessonDB></Select>
                ) : null}
              </Grid>
              {numberOfIns >= 1 ? (
                <Grid margin="0px 0px 10px 15px">
                  <Text bold>[수강악기 1 ▼]</Text>
                  <Select
                    id="ins1"
                    _onChange={updateID}
                    width="25%"
                    lessonDB></Select>
                  <Text bold _onClick={showInsSelect}>
                    [수강악기 2 ▼]
                  </Text>
                </Grid>
              ) : null}
              {numberOfIns >= 2 ? (
                <Grid margin="0px 0px 10px 15px">
                  <Select
                    id="ins2"
                    _onChange={updateID}
                    width="25%"
                    lessonDB></Select>
                  <Text bold _onClick={showInsSelect}>
                    [수강악기 3 ▼]
                  </Text>
                </Grid>
              ) : null}
              {numberOfIns >= 3 ? (
                <Grid margin="0px 0px 10px 15px">
                  <Select
                    id="ins3"
                    _onChange={updateID}
                    width="25%"
                    lessonDB></Select>
                  <Text bold _onClick={showInsSelect}>
                    [수강악기 4 ▼]
                  </Text>
                </Grid>
              ) : null}
              {numberOfIns >= 4 ? (
                <Grid margin="0px 0px 10px 15px">
                  <Select
                    id="ins4"
                    _onChange={updateID}
                    width="25%"
                    lessonDB></Select>
                </Grid>
              ) : null}
            </Grid>
            <br />
            {confirmMbrBtn ? (
              <React.Fragment>
                <hr />
                <Grid padding="1% 3% 0%">
                  <Text margin="0px" size="18px" bold>
                    입력한 정보를 확인하세요
                  </Text>
                  <Text margin="0px" size="16px">
                    &nbsp;&nbsp;♠ 회원정보 :&nbsp;{rcvInfo.inbox_member.name}
                    &nbsp;/&nbsp;
                    {rcvInfo.inbox_member.mobile}&nbsp;/&nbsp;
                    {rcvInfo.inbox_member.dob}&nbsp;/&nbsp;
                    {rcvInfo.inbox_member.orch_name}
                  </Text>
                  <Text margin="0px" size="16px">
                    &nbsp;&nbsp;{confirmSend}
                  </Text>
                </Grid>
                <Grid is_flex padding="0px">
                  <Grid is_flex padding="0px" width="15%"></Grid>
                  <Grid is_flex padding="15px">
                    <Button
                      width="50%"
                      margin="0px 2px"
                      id="confirmMember"
                      _onClick={sendNewMember}>
                      확인
                    </Button>
                    <Button
                      width="50%"
                      margin="0px 2px"
                      id="cancelMember"
                      _onClick={sendNewMember}>
                      취소
                    </Button>
                  </Grid>
                  <Grid is_flex padding="0px" width="15%"></Grid>
                </Grid>
              </React.Fragment>
            ) : null}

            <hr />

            <Grid padding="1% 3% 0%">
              <Text margin="0px" size="24px" bold>
                레슨 링크 DB
              </Text>
              <Select _onChange={change_Lesson} lessonDB></Select>
              <Button
                text="조회"
                width="20%"
                margin="3px"
                _onClick={action_B}></Button>
              <Text margin="20px 5px 10px" size="16px" bold>
                ▶ [{rcvInfo.inbox.id}] : {rcvInfo.inbox.tName} (
                {rcvInfo.inbox.학기}
                학기 {rcvInfo.inbox.주차}주차 / 강사 코드 {rcvInfo.inbox.tCode}{" "}
                / 레슨 순번 {rcvInfo.inbox.Sq})
              </Text>
              <Text margin="0px" size="16px">
                　&nbsp;&nbsp;레슨 제목&nbsp;&nbsp;:&nbsp;&nbsp;
                {rcvInfo.inbox.제목}
                <br />
                　&nbsp;&nbsp;YouTube&nbsp;&nbsp;:&nbsp;&nbsp;
                {rcvInfo.inbox.url ? rcvInfo.inbox.url : "없음"}
                <br /> 　&nbsp;&nbsp;Vimeo&nbsp;&nbsp;:&nbsp;&nbsp;
                {rcvInfo.inbox.vimeo ? rcvInfo.inbox.vimeo : "없음"}
              </Text>
            </Grid>

            <Grid padding="1% 3% 0%">
              <Text margin="0px" size="24px" bold>
                레슨 링크 DB 수정
              </Text>
              <Input
                placeholder={inputText.lessonID}
                width="10%"
                _onChange={updateID}
                noGrid></Input>
              <Input
                placeholder={inputText.lessonNotes}
                width="20%"
                _onChange={updateID}
                noGrid></Input>
              <Input
                placeholder={inputText.youtube}
                width="20%"
                _onChange={updateID}
                noGrid></Input>
              <Input
                placeholder={inputText.vimeo}
                width="20%"
                _onChange={updateID}
                noGrid></Input>
              <Button
                text="기존 정보 수정"
                id="updateLink"
                width="20%"
                margin="3px"
                _onClick={sendUpdateLink}></Button>
            </Grid>
            <Grid padding="1% 3% 0%">
              <Text margin="0px" size="24px" bold>
                레슨 링크 DB 새로입력
              </Text>
              <Select _onChange={updateID} width="25%" lessonDB></Select>
              <Button
                text="새로 입력"
                id="newEntryLink"
                width="20%"
                margin="3px"
                _onClick={sendNewLink}></Button>
              <Grid>
                <Input
                  placeholder={inputText.lessonNotes}
                  width="25%"
                  _onChange={updateID}
                  noGrid></Input>
                <Input
                  placeholder={inputText.youtube}
                  width="25%"
                  _onChange={updateID}
                  noGrid></Input>
                <Input
                  placeholder={inputText.vimeo}
                  width="25%"
                  _onChange={updateID}
                  noGrid></Input>
              </Grid>
            </Grid>
            <br />
            {confirmBtn ? (
              <React.Fragment>
                <hr />
                <Grid padding="1% 3% 0%">
                  <Text margin="0px" size="16px" bold>
                    ID: {autoIdState.id} ({autoIdState.id_fx.semester}학기{" "}
                    {autoIdState.id_fx.week}주차 / 강사코드{" "}
                    {autoIdState.id_fx.tutor} / 레슨 순번{" "}
                    {autoIdState.id_fx.sequence})
                  </Text>
                  <Text margin="0px" size="16px">
                    레슨 제목: {autoIdState.title}
                  </Text>
                  <Text margin="0px" size="16px">
                    YouTube: {autoIdState.youtube}
                  </Text>
                  <Text margin="0px" size="16px">
                    Vimeo: {autoIdState.vimeo}
                  </Text>
                </Grid>
                <Grid is_flex padding="0px">
                  <Grid is_flex padding="0px" width="15%"></Grid>
                  <Grid is_flex padding="15px">
                    <Button
                      width="50%"
                      margin="0px 2px"
                      id="confirmLink"
                      _onClick={sendNewLink}>
                      확인
                    </Button>
                    <Button
                      width="50%"
                      margin="0px 2px"
                      id="cancelLink"
                      _onClick={sendNewLink}>
                      취소
                    </Button>
                  </Grid>
                  <Grid is_flex padding="0px" width="15%"></Grid>
                </Grid>
              </React.Fragment>
            ) : null}
            <hr />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <center>
            <AspectOutter>
              <AspectInner src={logo}></AspectInner>
            </AspectOutter>
            <Text margin="0px" size="150%" bold>
              .....관리자 인증 실패
            </Text>
            <br />
            <Text margin="0px" size="110%" bold>
              관리자 로그인을 해주세요.
            </Text>
          </center>
          <Grid padding="5%">
            <Grid is_flex padding="0px">
              <Grid is_flex padding="0px" width="5%"></Grid>
              <Grid is_flex padding="5px">
                <ElButton
                  height="50px"
                  bg="#DF5E5E"
                  margin="0px 15px"
                  onClick={() => {
                    props.history.replace("/login");
                  }}>
                  관리자 로그인
                </ElButton>
              </Grid>
              <Grid is_flex padding="0px" width="5%"></Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ElButton = styled.button`
  ${(props) => (props.width ? `width: ${props.width};` : "width: 100%")};
  height: ${(props) => props.height};
  color: white;
  font-size: 17px;
  font-weight: bold;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")};
  background-color: ${(props) => props.bg};
  box-sizing: border-box;
  border: none;
  border-radius: 7px 25px 10px;
  /* border-radius: 7px 25px 10px; */
`;

const AspectOutter = styled.div`
  width: 50%;
  padding: 10%;
`;

const AspectInner = styled.div`
  background-position: center;
  padding: 20%;
  background-image: url("${(props) => props.src}");
  background-size: contain;
  background-repeat: no-repeat;
`;

export default Manager;
