import React from "react";
import { Grid, Text, Image } from "../elements";

const Card = (props) => {
  const { image_url, user_name, post_id } = props;

  return (
    <Grid padding="16px" margin="8px 0px" is_flex bg="#FFFFFF">
      <Grid width="auto" margin="0px 8px 0px 0px">
        <Image size={85} shape="square" image_url={image_url} />
      </Grid>

      <Grid>
        <Text>
          <b>{user_name}</b>님이 댓글을 남겼습니다!
        </Text>
      </Grid>
    </Grid>
  );
};

Card.defaultProps = {
  image_url: "",
  user_name: "",
  post_id: null,
};

export default Card;
