import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { Naver } from ".";

const client_id = "e08e1ab9fc173a3ac2a2aa0af415c911";
const { host, protocol } = window.location;
const callbackUrl = `${protocol}//${host}/auth_kakao`;

const Kakao = () => {
  // const location = useLocation();

  // const getNaverToken = () => {
  //   if (!location.hash) return;
  //   const token = location.hash.split("access_token=")[1].split("&")[0];
  //   const state = location.hash.split("state=")[1].split("&")[0];
  //   const rqst =
  //     "https://nid.naver.com/oauth2.0/token?client_id=" +
  //     client_id +
  //     "&client_secret=" +
  //     "&grant_type=authorization_code&state=" +
  //     state +
  //     "&code=" +
  //     token;

  //   window.alert(rqst);
  //   const header = "Bearer " + token;

  //   // fetch("https://api.npms.io/v2/search?q=react", {
  //   //   method: "get",
  //   // })
  //   //   .then((res) => console.log(res))
  //   //   .catch(console.log(1));

  //   fetch("https://openapi.naver.com/v1/nid/me", {
  //     method: "get",
  //     headers: {
  //       Authorization: header,
  //     },
  //   })
  //     .then((res) => console.log(res))
  //     .catch(console.log(1));

  //   window.location.href = rqst;
  // };

  // const test1 = () => {
  //   fetch("https://api.npms.io/v2/search?q=react", {
  //     method: "get",
  //   })
  //     .then((res) => {
  //       console.log(res);
  //       return res.json();
  //     })
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch(console.log(111));
  // };

  // http://localhost:3000/auth3?
  // code=tzgUXoCXgQEd7XHHcMEyrQmHtRq9NDLrFx2nM62Oa6LS2M7J8kBiUD20lmGGoi_8trmLSwo9dRkAAAF-D2sSnA

  const test2 = (props) => {
    const tid = props.view.location.pathname.split("/")[2];
    sessionStorage.setItem("tid", tid);
    tid
      ? sessionStorage.setItem("rq_type", "kakao_reg")
      : sessionStorage.setItem("rq_type", "kakao_login");
    const rqst =
      "https://kauth.kakao.com" +
      "/oauth/authorize?client_id=" +
      client_id +
      "&redirect_uri=" +
      callbackUrl +
      "&response_type=code";
    window.location.href = rqst;
  };

  // const { Kakao } = window;
  // var KAKAO_LOGIN_API_URL = "https://kauth.kakao.com/oauth/";

  // const kakaoLogin = (props) => {
  //   const { history } = props;
  //   console.log("kakao.js");
  //   Kakao.Auth.login({
  //     success: function (authObj) {
  //       fetch(`${KAKAO_LOGIN_API_URL}`, {
  //         method: "POST",
  //         mode: "no-cors",
  //         body: JSON.stringify({
  //           access_token: authObj.access_token,
  //         }),
  //       })
  //         .then((res) => res.json())
  //         .then((res) => {
  //           localStorage.setItem("Kakao_token", res.access_token);
  //           if (res.access_token) {
  //             alert("안녕하세요 토큰");
  //             history.push("/login");
  //           }
  //         });
  //     },
  //     fail: function (err) {
  //       alert(JSON.stringify(err));
  //     },
  //   });
  // };

  //   useEffect(() => {
  //     // naverLogin();
  //     // getNaverToken();
  //     test1();
  //     test2();
  //   });
  return (
    <React.Fragment>
      <center>
        <div id="kakaoIdLogin" />
        {/* <button fill className="btn_kakao" onClick={test2}>
          카카오 로그인_버튼
        </button> */}
        <img
          src="//k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg"
          width="30%"
          alt="카카오로그인"
          onClick={test2}
        />
      </center>
    </React.Fragment>
  );
};

export default Kakao;
