import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCuixnSlDYQtUFVS0wbx-Z4G9Q5fuekRs4",
  authDomain: "js-react-adv.firebaseapp.com",
  projectId: "js-react-adv",
  storageBucket: "js-react-adv.appspot.com",
  messagingSenderId: "625256805812",
  appId: "1:625256805812:web:17c99405259c828485eee1",
  measurementId: "G-BNEE2ZVHM7",
};

firebase.initializeApp(firebaseConfig);

const apiKey = firebaseConfig.apiKey;
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

export { auth, apiKey, firestore, storage };
