import React from "react";
import styled from "styled-components";

import { Grid } from "../elements";

const VideoPlayer = (props) => {
  const { lecture_url } = props;

  const is_vimeo = lecture_url.indexOf("vimeo") > 0 ? true : false;

  const url = is_vimeo
    ? `https://player.vimeo.com/video/${lecture_url.split("/")[3]}`
    : `https://www.youtube-nocookie.com/embed/${
        lecture_url.split("youtu.be/")[1]
      }?rel=0`;
  // console.log(props, url, lecture_url);
  // 강의 주소 유효성 검사하는 것이 좋음

  if (is_vimeo) {
    return (
      <React.Fragment>
        <Grid>
          <Grid is_flex padding="16px">
            <ElIframe
              title="Lesson"
              src={url}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen></ElIframe>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid>
          <Grid is_flex padding="16px">
            <ElIframe
              title="Lesson"
              src={url}
              frameBorder="0"
              allow="accelerometer; 
                      autoplay; 
                      clipboard-write; 
                      encrypted-media; 
                      gyroscope; 
                      picture-in-picture"
              allowFullScreen></ElIframe>
            {/* <Image shape="circle" src={props.src} /> */}
            {/* <Text bold>{props.user_info.user_name}</Text> */}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

VideoPlayer.defaultProps = {
  lecture_url: "https://youtu.be/1kQOe1Wxic8",
};
// 1kQOe1Wxic8

const ElIframe = styled.iframe`
  ${"" /* border: 1px solid #212121; */}
  width: 100vw;
  height: 50vh;
  padding: 12px 4px;
  ${"" /* box-sizing: border-box; */}
`;

export default VideoPlayer;
