import "./App.css";
import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../redux/configureStore";

import styled from "styled-components";

import {
  PostList,
  Login,
  Signup,
  PostWrite,
  PostDetail,
  Notification,
  Lesson,
  Manager,
  NotFound,
  KakaoLogin,
  Naver,
  OAuth,
} from "../pages";

import Header from "../components/Header";
import { Grid, Button } from "../elements";
import Permit from "./Permit";

import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "../redux/modules/user";

import { apiKey } from "./firebase";

function App() {
  const dispatch = useDispatch();

  const _session_key = `firebase:authUser:${apiKey}:[DEFAULT]`;
  const is_session = sessionStorage.getItem(_session_key) ? true : false;

  React.useEffect(() => {
    if (is_session) {
      dispatch(userActions.loginCheckFB());
    }
  }, []);

  return (
    <React.Fragment>
      <Grid>
        <Header></Header>
        <ConnectedRouter history={history}>
          <Switch>
            {/* <Route path="/" exact component={PostList} /> */}
            {/* <Route path="/signup" exact component={Signup} /> */}
            {/* <Route path="/write" exact component={PostWrite} />
            <Route path="/write/:id" exact component={PostWrite} />
            <Route path="/post/:id" exact component={PostDetail} />
            <Route path="/search" exact component={Search} />
            <Route path="/noti" exact component={Notification} /> */}
            <Route path="/naver" exact component={Naver} />
            <Route path="/auth_kakao" exact component={OAuth} />
            <Route path="/login" exact component={Login} />
            <Route path="/kakao/:member_code" exact component={KakaoLogin} />
            <Route path="/lesson/:member_code" exact component={Lesson} />
            <Route path="/manager/:member_code" exact component={Manager} />
            <Route component={NotFound} />
          </Switch>
        </ConnectedRouter>
      </Grid>
      {/* <Permit>
        <Button
          is_float
          text="+"
          _onClick={() => {
            history.push("/write");
          }}></Button>
      </Permit> */}
    </React.Fragment>
  );
}

// const MyStyled = styled.div`
//   width: 50vw;
//   height: 50px;
//   padding: 10px;
//   color: blue;
//   &:hover: {
//     background-color: yellow;
//   }
//   background-color: ${(props) => (props.bgColor ? props.bgColor : "purple")};
// `;

export default App;

// =========================

// function App() {
//   return (
//     <React.Fragment>
//       <Grid>
//         <Header></Header>
//         <ConnectedRouter history={history}>
//           <Route path="/" exact component={PostList} />
//           <Route path="/login" exact component={Login} />
//           <Route path="/signup" exact component={Signup} />
//         </ConnectedRouter>
//       </Grid>
//     </React.Fragment>
//   );
// }
// export default App;

// function App() {
//   return (
//     <React.Fragment>
//       <Grid>
//         <Header></Header>
//         <BrowserRouter>
//           <Route path="/" exact component={PostList} />
//           <Route path="/login" exact component={Login} />
//           <Route path="/signup" exact component={Signup} />
//         </BrowserRouter>
//       </Grid>
//     </React.Fragment>
//   );
// }
